<template>
  <div id="nav">
    <TopBar @toggle-menu="toggleMenu" :showMenu="showMenu"/>
  </div>
  <div id="view">
    <router-view :calendarWeek="getCalendarWeek" :firstDay="firstDay" :lastDay="lastDay" :news="news"/>
  </div>
</template>

<script>
import TopBar         from "@/components/TopBar/TopBar";
import {infoRequest}  from '@/helper/contstants'
import dayjs          from "dayjs";
import advancedFormat from 'dayjs/plugin/advancedFormat'
import weekOfYear from 'dayjs/plugin/weekOfYear'
dayjs.extend(advancedFormat);
dayjs.extend(weekOfYear);

export default {
  name: "App",
  components: {
    TopBar
  },
  computed: {
    getCalendarWeek() {
      const thisWeekDay = parseInt(dayjs().format('d'));
			// Week start on Sunday so menu for next week was shown on Saturday
      if (thisWeekDay === 6) {
        return parseInt(dayjs().format('w')) + 1
      } else {
        return parseInt(dayjs().format('w'))
      }
    }
  },
  data() {
    return {
      showMenu: false,
      firstDay: dayjs().day(1).format('DD.MM.YYYY'),
      lastDay: dayjs().day(5).format('DD.MM.YYYY'),
			news: ''
    }
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu
    },
		getNewsFromApi() {
    	const newsRequest = infoRequest;
    	newsRequest
			.then(response => {
				if (response.data.info && response.data.info.length > 0){
					this.news = response.data.info[0].info
				}
			})
			.catch(exp => {
				console.log('Could not fetch Infos form API: ', exp)
			})
		}
  },
	created() {
    console.log(this.$i18n.t('global.title'))
  	this.getNewsFromApi();
	}
};
</script>

<style lang="scss">
#app {
  font-family: $fontFamily;
  font-size: 14px;
  height: 100vh;
  overflow: scroll;
  #nav {
    height: 52px;
  }
  #view {
    width: calc(100vw - 16px);
    height: calc(100vh - 100px);
    margin-top: 0;
    padding: $spaceMd;
  }
}
</style>
