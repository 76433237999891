<template>
<div class="newsBox">
  <div class="newsText">
  <h3>News:</h3>
  <p>{{news}}</p>
  </div>
  <div class="newsIcon">
    <i class="fas fa-newspaper"/>
  </div>
</div>
</template>

<script>
export default {
  name: "NewsBox",
  props: {
    news: String
  }
};
</script>

<style scoped lang="scss">
.newsBox {
  border: 1px solid $primary;
  border-radius: 8px;
  padding: 8px;
  margin: $spaceLg 0;
  display: flex;
  align-items: center;
  .newsText {
    flex-grow: 1;
    h3 {
      font-size: 16px;
      color: $primary;
      margin: 0 0 4px 0;
    }
    p {
      color: $primary;
      margin: 0
    }
  }
  .newsIcon {
    flex-basis: 40px;
    text-align: center;
    font-size: 20px;
    color: $primary;
  }
}
</style>