<template>
	<div class="menuPanel">
  <h2>{{ $t('global.menu-order.selection') }}</h2>
  <div :key="day" v-for="day of getAllDays">
    <div :class="allowOpen(day) ? 'dayContainer' : 'dayContainer disabled'">
      <div class="dayHeader" @click="allowOpen(day) ? toggleOpenDay(day) : null">
        <span>{{day}}</span>
				<span v-show="selectedMenu[day.split(',')[0]] !== null" class="label"><i class="fas fa-check"/> </span>
        <span v-show="!allowOpen(day)">{{ $t('global.menu-order.cant-order') }}</span>
        <span><i class="fas fa-plus-square" /></span>
      </div>
      <div :class="selectedMenu[day.split(',')[0]] === menu.id ? 'menuContainer selected' : 'menuContainer' "
           v-for="(menu, index) in getMenusByDay[day]" v-show="showDay[day.split(',')[0]]">
        <div class="menu">
          <div class="menuName">
            <span class="menuNr">{{index + 1}}</span>
            <div class="menuTitle">
              <span v-if="$i18n.locale === 'de'">{{menu.menu_name}}</span>
              <span v-if="$i18n.locale === 'en'">{{menu.menu_name_en}}</span>
              <br/>
              <span class="additions">{{menu.additions}}</span>
            </div>
          </div>
        </div>
        <div class="priceContainer">
          <div class="selector">
            <button
              :class="selectedMenu[day.split(',')[0]] === menu.id ? 'selected' : ''"
              @click="() => handleSelect(menu.id, day)">
              {{$t('global.menu-order.select')}}
            </button>
          </div>
          <div class="price">
            {{menu.price}} &euro;
          </div>
        </div>

      </div>
    </div>
  </div>
	</div>
</template>

<script>
import dayjs        from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
dayjs.extend(isSameOrAfter)
import {daysGerman, APP_PAGE, orderTime} from "../helper/contstants";

export default {
  name: "Menus",
  props: {
    calendarWeek: Number,
    menus: Array,
  },
  data() {
    return {
      showDay: {
        Montag: false,
        Dienstag: false,
        Mittwoch: false,
        Donnerstag: false,
        Freitag: false
      },
      selectedMenu: {
				Montag: null,
				Dienstag: null,
				Mittwoch: null,
				Donnerstag: null,
				Freitag: null
      },
			actDay: dayjs().format('YYYY-MM-DD')
    }
  },
  computed: {
    getMenusByDay() {
      let menusByDay = {};
      if (this.menus.length > 0) {
        this.menus.forEach((menu) => {
          const newMenu = {
            menu_name: menu.menu_name,
            menu_name_en: menu.menu_name_en,
            date: menu.date,
            price: menu.price.toFixed(2),
            weekday: menu.weekday,
            additions: menu.additions,
            id: menu.id
          }
          const dayKey = daysGerman[menu.weekday] + ', ' + dayjs(menu.date).format('DD.MM.')
          if (!menusByDay.hasOwnProperty(dayKey)) {
            menusByDay[dayKey] = [{...newMenu}]
          } else {
            menusByDay[dayKey].push(newMenu)
          }
        })
      }
      return menusByDay
    },
    getAllDays() {
      return Object.keys(this.getMenusByDay)
    },
  },
  methods: {
    toggleOpenDay(day) {
      const dayKeys = Object.keys(this.showDay);
      dayKeys.forEach(dayKey => {
        if (day.includes(dayKey)) {
          this.showDay[dayKey] = !this.showDay[dayKey]
        }
      })
    },
    handleSelect(id, day) {
      const dayKeys = Object.keys(this.selectedMenu);
      let newSelectMenu = this.selectedMenu;
      dayKeys.forEach(dayKey => {
        if (day.includes(dayKey)) {
          if (newSelectMenu[dayKey] === id) {
						newSelectMenu[dayKey] = null
          } else {
						newSelectMenu[dayKey] = id
          }
        }
      });
			this.selectedMenu = newSelectMenu
			this.$emit('handleMenuSelect', newSelectMenu)
    },
		allowOpen(day) {
			const dateOnly = day.split(', ')[1];
    	const dayFormated = dayjs().format('YYYY') + '-' + dateOnly.split('.')[1] + '-' + dateOnly.split('.')[0];

			if (dayjs(this.actDay).isAfter(dayFormated)) {
				return false
			} else if (dayjs(this.actDay).isSame(dayFormated) && dayjs().hour() > orderTime[APP_PAGE(window.location.href)]) {
				return false
			} else {
				return true
			}
		}
  }
};
</script>

<style scoped lang="scss">
.menuPanel {
	padding-bottom: 150px;
.dayContainer {
  border: 1px solid $primary;
  border-radius: 4px;
  margin-bottom: $spaceLg;
	&.disabled {
		opacity: 0.5;
	}
  .dayHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $dark;
    font-size: 16px;
    background-color: $primary;
    border-right: 4px;
    padding: 16px;
		.label {
			font-size: 12px;
			width: 12px;
			height: 12px;
			padding: 2px;
			background-color: $secondary;
			color: $dark;
			border-radius: 50%;
			box-shadow: 0 0 5px rgba(0,0,0,0.75);
			margin-right: auto;
			margin-left: 0px;
			margin-top: -15px;
		}
  }
  .menuContainer {
    color: $dark;
    margin-top: $spaceMd;
    margin-bottom: $spaceXs;
    border-bottom: 1px solid $dark;
    &.selected {
      background-color: #d9ff9f;
    }
    .menu {
      padding: 4px;
      display: flex;
      flex-direction: column;
      .menuName {
        display: flex;
        align-items: center;
        .menuNr {
          min-width: 24px;
          height: 24px;
          border-radius: 50%;
          background-color: $primary;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: $spaceLg;
          color: $light;
        }
        .menuTitle {
          font-weight: bold;
          font-size: 16px;
          .additions {
            font-size: 12px;
          }
        }
      }
    }
    .priceContainer {
      display: flex;
      align-items: center;
      justify-content: stretch;
      padding: $spaceLg $spaceMd;
      .selector {
        flex-basis: 50%;
        width: 50%;
        border: 1px solid $dark;
        button {
          padding: 7px;
          border: none;
          background-color: transparent;
          width: 100%;
          &.selected {
            background-color: $dark;
            color: $secondary;
          }
        }
      }
      .price {
        flex-basis: 50%;
        width: 50%;
        border: 1px solid $dark;
        background-color: $dark;
        color: $secondary;
        font-size: 16px;
        text-align: center;
        padding: $spaceXs;
        height: 22px;
      }
    }
  }
}
}
</style>
