import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import {createI18n} from "vue-i18n";
import 'normalize.css/normalize.css'
import de from './lang/de.json'
import en from './lang/en.json'

const i18n = createI18n({
    locale: 'de',
    fallbackLocale: 'de',
    messages: {
        de: de,
        en: en
    },
    legacy: true
})

createApp(App).use(router).use(i18n).mount('#app')
