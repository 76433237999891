<template>
  <div class="home">
    <Header :calendarWeek="calendarWeek" :firstDay="firstDay" :lastDay="lastDay" :isThisWeek="true"/>
    <NewsBox :news="news" />
    <NameBox :orderName="orderName" @handleName="(e) => handleName(e)" @handleDayTime="(e) => handleDayTime(e)" @handleWerk="(e) => handleWerk(e)"/>
		<Menus :calendarWeek="calendarWeek" :menus="menus" @handleMenuSelect="handleMenuSelect"/>
		<div class="orderPanel">
    	<Button :disable="canOrder" btn-color="#ffca57" :btn-label="$t('global.helper.order-now')" @handleClick="handleButtonClick" />
			<HelperText v-show="generateHelperText !== ''" :text="generateHelperText" />
		</div>
		<div v-show="showMessage.open">
			<MessageDialog :message="showMessage.message" :type="showMessage.type"/>
		</div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header                                                         from '@/components/Header.vue'
import NewsBox                                                        from '@/components/NewsBox.vue'
import Menus                                                          from "@/components/Menus"
import NameBox                                                        from "@/components/NameBox/NameBox";
import Button                                                         from "../components/Button";
import dayjs                                                          from "dayjs";
import {API_HOST, API_STORE_ORDER, APP_PAGE, daysGerman, menuRequest} from "../helper/contstants";
import axios                                                          from "axios";
import HelperText                                                     from "../components/HelperText";
import MessageDialog                                                  from "../components/MessageDialog";

export default {
  name: 'Home',
  components: {
		MessageDialog,
		HelperText,
    Button,
    NameBox,
    Menus,
    Header,
    NewsBox
  },
	computed: {
  	generateHelperText() {
  		if (this.orderName === '') {
  			return this.$t('global.helper.enter-name')
			} else if (this.selectedMenus.length === 0) {
				return this.$t('global.helper.enter-menu')
			} else if (this.appPage === 'crown' && this.werk === '') {
				return this.$t('global.helper.enter-plant')
			} else {
  			return ''
			}
		},
		canOrder() {
			if (this.appPage === 'crown') {
				return this.selectedMenus.length === 0 || this.orderName === '' || this.werk === ''
			} else {
				return this.selectedMenus.length === 0 || this.orderName === ''
			}
		}
	},
  props: {
    calendarWeek: Number,
    firstDay: String,
    lastDay: String,
		news: String
  },
  data() {
    return {
      menus: [],
      orderName: '',
			dayTime: 'Mittag',
			werk: '',
			appPage: APP_PAGE(window.location.href),
			selectedMenus: [],
			order: [],
			showMessage: {
      	open: false,
				type: '',
				message: ''
			}
    }
  },
  methods: {
    handleName(event) {
      this.orderName = event.target.value;
    },
		handleDayTime(event) {
			this.dayTime = event.target.id;
		},
		handleWerk(event) {
			this.werk = event.target.id;
		},
    handleButtonClick() {
			let orders = []
			this.selectedMenus.forEach(order => {
				if (order) {
					orders.push({
						name: this.orderName,
						menu: order.menu_id,
						menu_id: order.id,
						day_time: this.dayTime,
						date: order.date,
						weekday: daysGerman[dayjs(order.date).format('dddd')],
						calendar_week: this.calendarWeek,
						location: APP_PAGE(window.location.href),
						fullMenu: 0,
						werk: this.werk
					})
				}
			})

      this.saveOrder(orders)
    },
		handleMenuSelect(menus) {
    	let newMenuSelection = [];
    	for (let selMenu in menus) {
    		newMenuSelection.push(this.menus.find(menu => menu.id === menus[selMenu]))
			}
			this.selectedMenus = newMenuSelection;
		},
		handleShowMessage(type, message) {
    	this.showMessage = {
    		open: true,
				type: type,
				message: message
			};
    	setTimeout(() => {
    		this.showMessage = {
					open: false,
					type: '',
					message: ''
				}
			}, 2500)
		},
		saveOrder(orders) {
			if (orders.length > 0) {
				orders.forEach(order => {
					axios.post(API_HOST + API_STORE_ORDER, order)
						.then(response => {
							this.handleShowMessage('success', this.$t('global.helper.order-placed'));
							this.orderName = '';
							this.selectedMenus = [];
							this.handleMenuSelect([]);
							this.order = [];
						})
						.catch(exp => {
							console.log('Speichern nicht möglich')
							this.handleShowMessage('error', this.$t('global.helper.order-error'));
						})
				})
			} else {
				this.handleShowMessage('warning',  this.$t('global.helper.enter-menu'));
				console.log('Keine Menüs ausgewählt')
			}
		},
  },
  created() {
    const menusRequest = menuRequest(this.calendarWeek);
    menusRequest
    .then(response => {
      this.menus = response.data.menus
    })
    .catch(exp => {
      console.log('Error loading Data: ', exp)
    })
  }
}
</script>
<style scoped lang="scss">
.home {
	.orderPanel {
		width: calc(100vw - 16px);
		height: 60px;
		position: fixed;
		left: 0;
		bottom: 0;
		z-index: 5;
		padding: $spaceMd;
		box-shadow: 0 3px 5px rgba(0,0,0,0.75);
		background-color: $light;
	}
}
</style>
