<template>
	<div class="helperText">
		{{text}}
	</div>
</template>

<script>
export default {
	name: "HelperText",
	props: {
		text: String
	}
}
</script>

<style lang="scss" scoped>
.helperText {
	color: $grey;
	font-style: italic;
	font-size: 12px;
	margin: 4px 0 0 0;
}
</style>
