<template>
  <nav>
    <div class="clickAwayArea" @click="$emit('toggle-flyout')" />

    <ul>
      <li><router-link to="/">{{$t('global.menu.home')}}</router-link></li>
      <li><router-link to="/next">{{$t('global.menu.next-week')}}</router-link></li>
      <li><router-link to="/about">{{$t('global.menu.about')}}</router-link></li>
      <li><router-link to="/impressum">{{$t('global.menu.imprint')}}</router-link></li>
    </ul>

    <img src="@/assets/coffee-company-weiss.png" alt="Coffee and Company" class="logo"/>
  </nav>
</template>

<script>
export default {
  name: "FlyOutMenu",
};
</script>

<style scoped lang="scss">

nav {
  position: absolute;
  width: 100vw;
  height: calc(100vh - 52px);
  top: 52px;
  .clickAwayArea {
    min-width: 80vw;
    width: calc(100vw - 250px);
    height: 100%;
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
  }
  ul {
    min-width: 20vw;
    width: 250px;
    height: 100%;
    margin-top: 0;
    background-color: $dark;
    color: $primary;
    z-index: 10;
    position: absolute;
    top: 0;
    right: 0;
    list-style: none;
    padding: 0;
    li {
      padding: 10px 0 10px 20px;
      &:hover {
        background-color: $secondary;
        a {
          color: $dark;
        }
      }
      a {
        color: $primary;
        text-decoration: none;
      }
    }
  }
  .logo {
    width: 200px;
    position: absolute;
    bottom: 40px;
    right: 8px;
    z-index: 10;
  }
}
</style>
