<template>
  <button :disable="disable" :class="disable ? 'btn disable' : 'btn'" @click="disable ? () => {} : $emit('handleClick')" :style="{backgroundColor: btnColor}">
    {{btnLabel}}
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    btnColor: String,
    btnLabel: String,
		disable: Boolean
  }
};
</script>

<style scoped lang="scss">
.btn {
  width: 100%;
  color: $dark;
  border: none;
  border-radius: 8px;
  padding: $spaceMd;
  font-size: 16px;
	&.disable {
		opacity: 0.5;
	}
}
</style>
