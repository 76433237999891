<template>
  <div class="orderContainer">
    <div class="nameContainer" :style="appPage === 'crown' ? {flexBasis: '35%'} : {flexBasis: '100%'}">
      <h2>{{ $t('global.name-box.order-for') }}</h2>
      <input
          class="text"
        type="text"
        :value="orderName"
        :placeholder="$t('global.name-box.name')"
        @change="(e) => $emit('handleName', e)"
      />
    </div>
    <div v-show="appPage === 'crown'" class="nameContainer timeContainer">
      <h2>{{ $t('global.name-box.select-time') }}</h2>
      <div class="inputGroupContainer">
        <div class="inputGroup">
          <label for="mittag">{{ $t('global.name-box.lunch') }}</label>
          <input type="radio" name="time" id="mittag" checked @change="(e) => $emit('handleDayTime', e)"/>
        </div>
<!--        <div class="inputGroup">-->
<!--          <label for="abend">{{ $t('global.name-box.dinner') }}</label>-->
<!--          <input type="radio" name="time" id="abend" @change="(e) => $emit('handleDayTime', e)"/>-->
<!--        </div>-->
      </div>
    </div>
		<div v-show="appPage === 'crown'" class="nameContainer timeContainer">
			<h2>{{ $t('global.name-box.select-plant') }}</h2>
			<div class="inputGroupContainer">
				<div class="inputGroup">
					<label for="werk1">{{ $t('global.name-box.plant') }} 1</label>
					<input type="radio" name="loc" id="werk1" @change="(e) => $emit('handleWerk', e)"/>
				</div>
        <div class="inputGroup">
          <label for="werk2">{{ $t('global.name-box.plant') }} 2</label>
          <input type="radio" name="loc" id="werk2" @change="(e) => $emit('handleWerk', e)"/>
        </div>
				<div class="inputGroup">
					<label for="vitesco">Vitesco</label>
					<input type="radio" name="loc" id="vitesco" @change="(e) => $emit('handleWerk', e)"/>
				</div>

        <div class="inputGroup">
          <label for="vitescomitte">Vitesco Mitte</label>
          <input type="radio" name="loc" id="vitescomitte" @change="(e) => $emit('handleWerk', e)"/>
        </div>

        <div class="inputGroup">
          <label for="cellforce">Cellforce</label>
          <input type="radio" name="loc" id="cellforce" @change="(e) => $emit('handleWerk', e)"/>
        </div>
        <div class="inputGroup">
          <label for="cellforceaic">Cellforce AIC</label>
          <input type="radio" name="loc" id="cellforceaic" @change="(e) => $emit('handleWerk', e)"/>
        </div>
			</div>
		</div>
  </div>
</template>

<script>
import {APP_PAGE} from '../../helper/contstants'

export default {
  name: "NameBox",
  props: {
    orderName: String
  },
  data() {
    return {
      appPage: APP_PAGE(window.location.href)
    }
  }
};
</script>

<style scoped lang="scss">
.orderContainer {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  .nameContainer {
  border: 1px solid $primary;
  border-radius: 8px;
  padding: $spaceMd;
  display: flex;
  flex-direction: column;
  input.text {
    width: calc(100% - 8px);
    font-size: 16px;
    border: 1px solid $primary;
    border-radius: 4px;
    color: $dark;
    padding: 4px;
  }
}
  .timeContainer {
    flex-basis: calc(50%);
    margin-left: $spaceLg;
    .inputGroupContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .inputGroup {
        flex-grow: 1;
        display: flex;
        justify-content: flex-start;
        input {
          margin-left: 10px;
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .orderContainer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: calc(100% - 16px);
    flex-direction: column;
    .nameContainer {
      flex-grow: 1;
      flex-basis: calc(100% - 16px) !important;
      width: calc(100% - 16px);
      margin-bottom: $spaceMd;
      .inputGroupContainer {
        flex-wrap: wrap;
        .inputGroup {
          flex-basis: 160px;
          padding: 12px 0;
        }
      }
    }
    .timeContainer {
      flex-grow: 1;
      flex-basis: calc(100% - 16px) !important;
      width: calc(100% - 16px);
      margin-left: 0;
    }
  }
}
</style>
