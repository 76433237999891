<template>
	<div class="messageOverlay"></div>
	<div :class="'messageDialog '+ type">
		<span>{{message}}</span>
	</div>
</template>

<script>
export default {
	name: "MessageDialog",
	props: {
		message: String,
		type: String
	}
}
</script>

<style scoped lang="scss">
.messageOverlay {
	width: 100vw;
	height: 100vh;
	background-color: rgba(0,0,0,0.5);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 50;
}
.messageDialog {
	width: 20vw;
	min-height: 40px;
	position: absolute;
  left: calc(50% - 10vw);
  top: calc(50% - 20px);
	z-index: 55;
	padding: 10px;
	border-radius: 8px;
	box-shadow: 0 0 25px rgba(0,0,0,0.75);
	display: flex;
	align-items: center;
	justify-content: center;
  background-color: $light;
	&.success {
		background-color: #d9ff9f;
  }
  &.error {
	background-color: #db5757;
  }
  &.warning {
	background-color: #ffcf67;
  }
}

</style>
